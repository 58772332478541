import { useEffect, useState } from 'react';
import LenderPerformanceMock from '../../components/LenderPerformanceMock';
import { getLenderPerformanceMockApi } from '../../api/lenderApi';
import { AbsoluteCenter, Heading, Spinner } from '@chakra-ui/react';
import WhiteBox from '../../components/WhiteBox';

function Lenders() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    getLenderPerformanceMockApi()
      .then((_data) => {
        setData(_data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <AbsoluteCenter><Spinner /></AbsoluteCenter>
  }

  if (!data) {
    return <AbsoluteCenter><WhiteBox><Heading size={'l'}>No Data Yet.</Heading></WhiteBox></AbsoluteCenter>
  }

  return  <LenderPerformanceMock data={data} />;
}

export default Lenders;
