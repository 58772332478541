import { AbsoluteCenter, Box, Spinner } from '@chakra-ui/react';

function OverlaySpinnerWrapper({ children, show, isFixed }) {
  return (
    <Box>
      {children}
      {show && (
        <Box
          position={isFixed ? 'fixed' : 'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="white"
          opacity={0.7}
        >
          <AbsoluteCenter>
            <Spinner />
          </AbsoluteCenter>
        </Box>
      )}
    </Box>
  );
}

export default OverlaySpinnerWrapper;
