import { Element } from 'react-scroll';
import { Box } from '@chakra-ui/react';

import { useState, useContext, useEffect } from 'react';
import { getCustomerPerformanceApi } from '../../../api/customerApi';
import { CustomerContext } from '../../../context/customerContext';

import HomeMainSection from './HomeMainSection';
import MarketingSpend from './MarketingSpend';
import Funding from './Funding';

function HomeDemo() {
  const { customer, isCustomerLoading } = useContext(CustomerContext);
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (customer) {
      getCustomerPerformanceApi(customer?.id)
        .then((_performanceData) => {
          let data = _performanceData;
          data.nextFunding.daysLeft = 10;
          data.nextPayment.daysLeft = 15;
          setPerformanceData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [customer]);


  return (
    <Box pb={500} position={'relative'}>
      <Element name={'insights'}>
        <HomeMainSection />
      </Element>
      <Element name={'expenses'}>
        <MarketingSpend />
      </Element>
      <Element name={'funding'}>
        <Funding performanceData={performanceData} loading={loading} />
      </Element>
    </Box>
  );
}

export default HomeDemo;
