import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Tooltip,
  useToast
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { getAllCustomersApi } from 'api/adminApi';
import ContentBox from 'components/ContentBox';
import DataTable from 'components/DataTable';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { HiUserAdd } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import AddUserModal from './AddUserModal';

const columnHelper = createColumnHelper();

const createColumns = (onAddUserCellClick) => {
  const columns = [
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'ID',
      // enableSorting: false,
    }),
    columnHelper.accessor('companyName', {
      cell: (info) => info.getValue(),
      header: 'Company Name',
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.display({
      id: 'email',
      cell: (info) => {
        const { users } = info.row.original;
        return (
          <>
            {users.map((user) => {
              return <Box key={user.id}>{user.email}</Box>;
            })}
          </>
        );
      },
      header: 'Email',
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
      header: 'Created At',
    }),
    columnHelper.accessor('lastLoginAt', {
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
      header: 'Last Login',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const customerId = info.row.original.id;
        return (
          <Flex gap={5} justify={'space-between'}>
            <Tooltip label={'View Customer'} aria-label={'View Customer'}>
              <Link as={NavLink} to={`/customers/${customerId}`}>
                <ViewIcon color={'black.main'} boxSize={5} />
              </Link>
            </Tooltip>
            <Tooltip label={'Add New Customer'} aria-label={'Add New Customer'}>
              <Button
                variant={'link'}
                minW={0}
                onClick={() => onAddUserCellClick(customerId)}
              >
                <Icon as={HiUserAdd} color={'black.main'} boxSize={5} />
              </Button>
            </Tooltip>
          </Flex>
        );
      },
      meta: {
        isActios: true,
      },
    }),
  ];

  return columns;
};

const defaultSorting = [{ id: 'createdAt', desc: true }];

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const toast = useToast();

  useEffect(() => {
    loadCustomers();
  }, []);

  useEffect(() => {
    setColumns(createColumns(onAddUserCellClick));
  }, [customers]);

  const loadCustomers = ({ onSuccess } = {}) => {
    setLoading(true);
    getAllCustomersApi()
      .then((_customers) => {
        setCustomers(transformCustomers(_customers));
        setCompanyNames(_customers.map((c) => c.companyName));
        onSuccess && onSuccess();
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Error loading customers',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const transformCustomers = (_customers) => {
    return _customers.map((customer) => {
      return {
        ...customer,
        createdAt: customer.createdAt ? new Date(customer.createdAt) : null,
        lastLoginAt: customer.lastLoginAt
          ? new Date(customer.lastLoginAt)
          : null,
      };
    });
  };

  const filterCustomers = (_customers) => {
    if (!searchValue) {
      return _customers;
    }

    return _customers.filter((customer) => {
      return (
        customer.companyName
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.users.some((user) =>
          user.email.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    });
  };

  const onAddUserCellClick = (customerId) => {
    setSelectedCustomerId(customerId);
    setShowAddUserModal(true);
  };

  return (
    <ContentBox>
      <Flex justify={'space-between'}>
        <Flex gap={4}>
          <InputGroup mb={6} w={300}>
            {' '}
            <Input
              type={'search'}
              placeholder={'Search'}
              onChange={(event) => setSearchValue(event.target.value)}
              value={searchValue}
            />
            <InputRightElement>
              <SearchIcon />
            </InputRightElement>
          </InputGroup>
          <IconButton variant={'outline'} icon={<FiRefreshCw />} onClick={loadCustomers} />
        </Flex>
        <Button
          onClick={() => {
            setSelectedCustomerId(null);
            setShowAddUserModal(true);
          }}
        >
          Create New Customer
        </Button>
      </Flex>

      <Box minH={300}>
        <OverlaySpinnerWrapper show={loading}>
          <DataTable
            data={filterCustomers(customers)}
            columns={columns}
            defaultSorting={defaultSorting}
            hasActionsColumn={true}
          />
        </OverlaySpinnerWrapper>
      </Box>

      <AddUserModal
        show={showAddUserModal}
        header={
          selectedCustomerId
            ? `Add new user to ${
                customers.find((c) => c.id === selectedCustomerId).companyName
              }`
            : 'Create New Customer'
        }
        selectedCustomerId={selectedCustomerId}
        companyNames={companyNames}
        onSubmitSuccess={() => {
          setShowAddUserModal(false);
          loadCustomers({
            onSuccess: () => {
              toast({
                title: 'Success',
                description: 'User created successfully',
                status: 'success',
              });
            },
          });
        }}
        onModalClose={() => setShowAddUserModal(false)}
      />
    </ContentBox>
  );
}

export default Customers;
