import { AbsoluteCenter, Box, Flex, Spinner } from '@chakra-ui/react';
import { getPerforamceCustomerListApi } from 'api/adminApi';
import { getCustomerPerformanceApi } from 'api/customerApi';
import { Select } from 'chakra-react-select';
import CustomerPerformance from 'components/CustomerPerformance';
import { useEffect, useState } from 'react';
import { selectChakraStyles } from 'themes/Select';

function CustomersPerformance() {
  const [loading, setLoading] = useState(true);
  const [loadingPerformance, setLoadingPerformance] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null); // options[0
  const [performanceData, setPerformanceData] = useState(null);

  useEffect(() => {
    getPerforamceCustomerListApi()
      .then((data) => {
        const _options = data
          .filter((item) => item.customerName)
          .map((item) => {
            return {
              label: item.customerName,
              value: item.customerId,
              selected: true,
            };
          });

        setOptions(_options);
        setSelectedOption(_options[0]);
        loadData(_options[0].value);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const loadData = (customerId) => {
    setLoadingPerformance(true);
    getCustomerPerformanceApi(customerId)
      .then((_performanceData) => {
        setPerformanceData(_performanceData);
      })
      .finally(() => {
        setLoadingPerformance(false);
      });
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <>
      <Flex justifyContent={'right'}>
        <Select
          isSearchable={false}
          chakraStyles={selectChakraStyles}
          value={selectedOption}
          //   _focusVisible={false}
          onChange={(option) => {
            setSelectedOption(option);
            loadData(option.value);
          }}
          options={options}
        ></Select>
      </Flex>
      <Box mt={10}>
        {loadingPerformance ? (
          <AbsoluteCenter>
            <Spinner />
          </AbsoluteCenter>
        ) : (
          <CustomerPerformance data={performanceData} />
        )}
      </Box>
    </>
  );
}

export default CustomersPerformance;
