import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  CONNECTOR_META_DATA,
  CONNECTOR_TYPE,
} from '../../../constants/connectorConstants';
import { sendAddNewConnectorClickGAEvent } from '../../../ga/connectorsGA';
import {
  ShopifyExtraPropsModalContent,
  ShopifyPartnerExtraPropsModalContent,
} from './ExtraPropsModalContent';

const connectorList = [
  CONNECTOR_TYPE.PLAID,
  CONNECTOR_TYPE.SHOPIFY,
  CONNECTOR_TYPE.APPSTORE,
  CONNECTOR_TYPE.GOOGLEPLAY,
  CONNECTOR_TYPE.FACEBOOK_ADS,
  CONNECTOR_TYPE.INSTAGRAM_BUSINESS,
  CONNECTOR_TYPE.APPSFLYER,
  CONNECTOR_TYPE.QUICKBOOKS,
  CONNECTOR_TYPE.STRIPE,
  CONNECTOR_TYPE.XERO,
  CONNECTOR_TYPE.SHOPIFY_PARTNER,
  CONNECTOR_TYPE.PAYPAL,
];

function ConnectorsListModalContent({ onAddConnector, onModalClose }) {
  const [selectedConnectorType, setSelectedConnectorType] = useState(null);

  const onSelectConnectorType = (connectorType) => {
    if (connectorType === selectedConnectorType) {
      setSelectedConnectorType(null);
    } else {
      setSelectedConnectorType(connectorType);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>Add a Connector</ModalHeader>
      <ModalCloseButton />
      <ModalBody maxH={'calc(100vh - 300px)'} overflowY={'auto'}>
        {connectorList.map((connectorType, index) => (
          <Box
            bg={connectorType === selectedConnectorType ? 'blue.100' : 'white'}
            key={`connector${index}`}
            py={4}
            borderBottom={'1px'}
            borderTop={index === 0 ? '1px' : '0px'}
            borderColor={'gray.border'}
            fontSize={'lg'}
            _hover={{
              bg:
                connectorType === selectedConnectorType
                  ? 'blue.100'
                  : 'blue.50',
              cursor: 'pointer',
            }}
            onClick={() => onSelectConnectorType(connectorType)}
          >
            <Flex px={6} gap={3} alignItems={'center'}>
              <Icon
                as={CONNECTOR_META_DATA[connectorType].icon}
                boxSize={10}
                mr={5}
              />
              {CONNECTOR_META_DATA[connectorType].displayName || ''}
            </Flex>
          </Box>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={onModalClose}>
          Close
        </Button>
        <Button
          variant={'primary'}
          type={'submit'}
          isDisabled={!selectedConnectorType}
          onClick={() => {
            sendAddNewConnectorClickGAEvent(selectedConnectorType);
            onAddConnector(selectedConnectorType);
          }}
        >
          Add
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

function AddConnectorModal({ customerId, onConnectorSelected }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addedConnectorType, setAddedConnectorType] = useState(null);

  const onSubmittedExtraProps = (data) => {
    onConnectorSelected({
      customerId,
      connectorType: addedConnectorType,
      data,
    });
    onModalClose();
  };

  const onAddConnector = (connectorType) => {
    if (
      [CONNECTOR_TYPE.SHOPIFY, CONNECTOR_TYPE.SHOPIFY_PARTNER].includes(
        connectorType
      )
    ) {
      setAddedConnectorType(connectorType);
    } else {
      onConnectorSelected({ customerId, connectorType });
      onModalClose();
    }
  };

  const onModalClose = () => {
    setAddedConnectorType(null);
    onClose();
  };

  const renderModalContent = ({ onModalClose }) => {
    if (!addedConnectorType) {
      return (
        <ConnectorsListModalContent
          onAddConnector={onAddConnector}
          onModalClose={onModalClose}
        />
      );
    }

    if (addedConnectorType === CONNECTOR_TYPE.SHOPIFY) {
      return (
        <ShopifyExtraPropsModalContent
          onSubmit={onSubmittedExtraProps}
          onModalClose={onModalClose}
        />
      );
    }

    if (addedConnectorType === CONNECTOR_TYPE.SHOPIFY_PARTNER) {
      return (
        <ShopifyPartnerExtraPropsModalContent
          onSubmit={onSubmittedExtraProps}
          onModalClose={onModalClose}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Button variant={'primary'} leftIcon={<AddIcon />} onClick={onOpen}>
        Add a Connector
      </Button>

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        {renderModalContent({ onModalClose })}
      </Modal>
    </>
  );
}

AddConnectorModal.propTypes = {
  customerId: PropTypes.string.isRequired,
  onConnectorSelected: PropTypes.func.isRequired,
};

export default AddConnectorModal;
