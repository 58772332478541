import { getSessionToken } from '@descope/react-sdk';
import axios from 'axios';
import { generateUUID } from '../helpers/uuidHelper';

let _userId = '';

axios.defaults.baseURL = process.env.REACT_APP_API_URL + '/api';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const sessionToken = getSessionToken();
    config.headers['correlation-id'] = generateUUID();
    config.headers['Authorization'] = `Bearer ${sessionToken}`;
    config.headers['x-app-type'] = process.env.REACT_APP_APP_TYPE;
    config.headers['x-user-id'] = _userId;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/';
      return;
    }
    return Promise.reject(error);
  }
);

export const setApiUserId = (userId) => {
  if (userId) {
    _userId = userId;
  }
  _userId = userId;
};
