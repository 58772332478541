const locale = 'en-US';

export function Currency({ value }) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(value);
}

export function Percentage({ value, maximumFractionDigits }) {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0 || maximumFractionDigits,
  }).format(value);
}

export function Number({ value, maximumFractionDigits }) {
  maximumFractionDigits = maximumFractionDigits === undefined ? 2 : maximumFractionDigits;
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
}

export function numberFormat(value, { maximumFractionDigits } = {}) {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits,
  }).format(value);
}

export function currencyFormat(value, { maximumFractionDigits } = {}) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maximumFractionDigits || 0,
  }).format(value);
}

export function percentageFormat(value, { maximumFractionDigits } = {}) {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0 || maximumFractionDigits,
  }).format(value);
}
