import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Heading,
  Flex,
  FormLabel,
  Button,
  Grid,
  GridItem,
  Stat,
  StatLabel,
  StatNumber,
  Center,
  useToast,
  FormControl,
  calc,
} from '@chakra-ui/react';

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis } from 'recharts';

import { PercentageNumberInput } from '../../../components/NumberInput';
import {
  Currency,
  Number,
  Percentage,
  currencyFormat,
  numberFormat,
  percentageFormat,
} from '../../../components/Number';
import OverlaySpinnerWrapper from '../../../components/OverlaySpinner';
import { getCustomerFundingLossSimApi } from '../../../api/adminApi';
import { calcROAS, convertCpiToCac } from './fundingCalc';

const resultsFields = [
  {
    label: 'CAC',
    name: 'cac',
    element: Currency,
  },
  {
    label: 'OPEX',
    name: 'opex',
    element: Currency,
  },
  {
    label: 'ARPU',
    name: 'arpu',
    element: Currency,
  },
  {
    label: 'First Month Retention',
    name: 'firstMonthRetentionPct',
    element: Percentage,
  },
  {
    label: 'Conversion Rate',
    name: 'conversionRate',
    element: Percentage,
  },
  {
    label: 'CPI',
    name: 'cpi',
    element: Currency,
  },
  {
    label: 'LTV 12',
    name: 'ltv12',
    element: Currency,
  },
  {
    label: 'LTV 24',
    name: 'ltv24',
    element: Currency,
  },
  {},
  {
    label: 'ROAS 100',
    name: 'roas100',
    element: Number,
  },
  {
    label: '7 Days Retention',
    name: 'sevenDaysRetentionPct',
    showNA: true,
  },
  {},
];

function LossSim({ fundingSimData, customerId, isCac }) {
  const [lossResults, setLossResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { handleSubmit, control, setValue, reset } = useForm();

  useEffect(() => {
    reset({ lossPct: 0.05 });
  }, []);

  const initCashBalanceKpiChartData = (data) => {
    return data ? data.sort((a, b) => a.cashBalance - b.cashBalance) : [];
    // return data || [];
  };

  const onFundingLossSimSubmit = ({ lossPct }) => {
    let data = fundingSimData;
    data.lossTarget = lossPct;

    setLoading(true);
    getCustomerFundingLossSimApi(customerId, data)
      .then((data) => {
        data.conversionRate = fundingSimData.companyInfo.cpi / data.cac;
        data.cpi = data.cac * fundingSimData.companyInfo.conversionRate;
        data.sevenDaysRetentionPct = 0;

        const cac = isCac
          ? fundingSimData.companyInfo.cacAvg
          : convertCpiToCac({
              cpi: fundingSimData.companyInfo.cpi,
              conversionRate: fundingSimData.companyInfo.conversionRate,
              cpiSd: fundingSimData.companyInfo.cpiSd,
            }).cacAvg;

        const { accAvgRevenueVector, roas100Index } = calcROAS({
          cac: cac,
          arpu: fundingSimData.companyInfo.arpuAvg,
          retentionPrediction: fundingSimData.companyInfo.retentionPrediction,
          isMonthly: true,
        });

        data.ltv12 =
          accAvgRevenueVector[11] *
          (data.arpu / fundingSimData.companyInfo.arpuAvg);
        data.ltv24 =
          accAvgRevenueVector[23] *
          (data.arpu / fundingSimData.companyInfo.arpuAvg);
        data.roas100 =
          (roas100Index + 1) / (data.arpu / fundingSimData.companyInfo.arpuAvg);

        setLossResults(data);
      })
      .catch((err) => {
        toast({
          title: 'Error',
          description: 'Failed to run simulation',
          status: 'error',
        });
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function CashBalanceKpiChart({ data, title, yTickFormatter }) {
    if (!data || data.length === 0) {
      return null;
    }

    return (
      <Box mt={'80px'} mx={10}>
        <Center fontSize={'xl'} fontWeight={500}>
          {title}
        </Center>
        <ResponsiveContainer width={'100%'} height={300}>
          <LineChart width={500} height={300} data={data}>
            <XAxis
              dataKey="cashBalance"
              tickFormatter={currencyFormat}
              tickSize={10}
              style={{ fontSize: '13px' }}
            />
            <YAxis
              width={100}
              type={'number'}
              scale={'linear'}
              tickFormatter={yTickFormatter}
              domain={[(dataMin) => dataMin * 0.9, (dataMax) => dataMax * 1.1]}
            />
            <Line
              dataKey={'kpi'}
              dot={false}
              stroke={'#2084F9'}
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  }

  function LossSimForm({ onSubmit }) {
    const onNumberInputChange = (name, value) => {
      setValue(name, value);
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Flex justify={'start'} align={'center'}>
            <FormLabel>Loss</FormLabel>
            <Controller
              control={control}
              name={'lossPct'}
              rules={{ required: true }}
              render={({ field }) => (
                <PercentageNumberInput
                  {...field}
                  showPrefix={true}
                  w={'150px'}
                  onChange={(value) => {
                    onNumberInputChange('lossPct', value);
                  }}
                />
              )}
            />

            <Button type={'submit'} ml={4}>
              Run Simulation
            </Button>
          </Flex>
        </FormControl>
      </form>
    );
  }

  function LossSimResults({ lossResults }) {
    const [cashBalanceKpiChartData, setCashBalanceKpiChartData] = useState({
      cac: null,
      arpu: null,
      opex: null,
      firstMonthRetention: null,
    });

    useEffect(() => {
      if (lossResults) {
        setCashBalanceKpiChartData({
          cac: initCashBalanceKpiChartData(lossResults.cacCashBalance),
          arpu: initCashBalanceKpiChartData(lossResults.arpuCashBalance),
          opex: initCashBalanceKpiChartData(lossResults.opexCashBalance),
          firstMonthRetention: initCashBalanceKpiChartData(
            lossResults.firstMonthRetentionCashBalance
          ),
        });
      }
    }, []);

    return (
      <>
        {lossResults && (
          <Grid
            templateColumns={'repeat(4, 1fr)'}
            alignContent={'center'}
            mt={50}
            mx={'auto'}
            gap={10}
            bg={'gray.50'}
            p={8}
            borderRadius={20}
            boxShadow={'box'}
          >
            {resultsFields.map((field) => {
              if (lossResults[field.name] === undefined) {
                return <Box></Box>;
              }
              return (
                <GridItem key={field.name}>
                  <Stat textAlign={'center'}>
                    <StatLabel whiteSpace={'nowrap'}>{field.label}</StatLabel>
                    <StatNumber>
                      {field.element ? (
                        <field.element value={lossResults[field.name]} />
                      ) : field.showNA ? (
                        'N/A'
                      ) : (
                        <Number value={lossResults[field.name]} />
                      )}
                    </StatNumber>
                  </Stat>
                </GridItem>
              );
            })}
          </Grid>
        )}

        <Flex>
          <Box width={'50%'}>
            <CashBalanceKpiChart
              data={cashBalanceKpiChartData.cac}
              title={'CAC'}
              yTickFormatter={(value) =>
                numberFormat(value, { maximumFractionDigits: 0 })
              }
            />
          </Box>
          <Box width={'50%'}>
            <CashBalanceKpiChart
              data={cashBalanceKpiChartData.arpu}
              title={'ARPU'}
              yTickFormatter={currencyFormat}
            />
          </Box>
        </Flex>
        <Flex>
          <Box width={'50%'}>
            <CashBalanceKpiChart
              data={cashBalanceKpiChartData.opex}
              title={'OPEX'}
              yTickFormatter={currencyFormat}
            />
          </Box>
          <Box width={'50%'}>
            <CashBalanceKpiChart
              data={cashBalanceKpiChartData.firstMonthRetention}
              title={'First Month Retention'}
              yTickFormatter={percentageFormat}
            />
          </Box>
        </Flex>
      </>
    );
  }

  return (
    <Box position={'relative'}>
      <Heading as={'h3'} size={'lg'} mb={8}>
        Loss Simulation
      </Heading>
      <OverlaySpinnerWrapper show={loading}>
        <LossSimForm onSubmit={onFundingLossSimSubmit} />
        <Box minH={820}>
          {!loading && <LossSimResults lossResults={lossResults} />}
        </Box>
      </OverlaySpinnerWrapper>
    </Box>
  );
}

export default LossSim;
