import { useSession, useUser } from '@descope/react-sdk';
import {useEffect} from 'react';
import {setApiUserId} from '../api';
import CustomerProvider from '../context/customerContext';
import LenderProvider from '../context/lenderContext';
import Login from '../pages/Login';

import { Box } from '@chakra-ui/react';
import {
  isCockpitApp,
  isCustomerApp,
  isDemoApp,
  isLenderApp,
} from '../helpers/envHelper';

import CockpitApp from './CockpitApp';
import CustomerApp from './CustomerApp';
import DemoApp from './DemoApp';
import LenderApp from './LenderApp';

function App() {
  const { isSessionLoading, isAuthenticated } = useSession();
  const { user, isUserLoading } = useUser();

  useEffect(() => {
    if (user) {
      setApiUserId(user?.userId);
    }
  }, [user]);

  if (isSessionLoading || isUserLoading) {
    return (
      <Box
        id={'main'}
        bg="linear-gradient(180deg, #C9FD37 0%, #F8F8F800 100%);"
        backgroundSize={'100% 836px'}
        backgroundColor={'#EFF7FF'}
        backgroundRepeat={'no-repeat'}
        position={'relative'}
        minH={'100vh'}
      ></Box>
    );
  }

  const loginComponent = <Login loading={isSessionLoading} />;

  if (isCustomerApp()) {
    return (
      <CustomerProvider isAuthenticated={isAuthenticated}>
        <CustomerApp
          loginComponent={loginComponent}
          isAuthenticated={isAuthenticated}
        />
      </CustomerProvider>
    );
  }

  if (isDemoApp()) {
    return (
      <CustomerProvider isAuthenticated={isAuthenticated}>
        <DemoApp
          loginComponent={loginComponent}
          isAuthenticated={isAuthenticated}
        />
      </CustomerProvider>
    );
  }

  if (isLenderApp()) {
    return (
      <LenderProvider isAuthenticated={isAuthenticated}>
        <LenderApp
          loginComponent={loginComponent}
          isAuthenticated={isAuthenticated}
        />
      </LenderProvider>
    );
  }

  if (isCockpitApp()) {
    return (
      <CockpitApp
        loginComponent={loginComponent}
        isAuthenticated={isAuthenticated}
      />
    );
  }
}

export default App;
