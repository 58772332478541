import { useUser } from '@descope/react-sdk';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import {
  AbsoluteCenter,
  Box,
  Container,
  Flex,
  Spinner,
} from '@chakra-ui/react';

import SideNavigationBar from './SideNavigationBar';
import TopNavigationBar from './TopNavigationBar';

function MainLayout({ withSideBar, loading, autoWidth }) {
  const { user, isUserLoading } = useUser();
  return (
    <Box
      id={'main'}
      bg="linear-gradient(180deg, #C9FD37 0%, #F8F8F800 100%);"
      backgroundSize={'100% 836px'}
      backgroundColor={'#EFF7FF'}
      backgroundRepeat={'no-repeat'}
      position={'relative'}
      minH={'100vh'}
      pb={5}
    >
      <TopNavigationBar user={user} />
      <Box w={'full'} pt={'130px'} h={'100%'}>
        <Flex>
          {withSideBar && <SideNavigationBar />}
          {loading || isUserLoading ? (
            <AbsoluteCenter>
              <Spinner />
            </AbsoluteCenter>
          ) : (
            <Container
              w={autoWidth ? 'auto' : 'full'}
              maxW={autoWidth ? 'none' : 'container.xl'}
              minW={'container.xl'}
              px={[3, 8]}
              mx={'auto'}
              transform={withSideBar ? 'translateX(90px)' : 'none'}
            >
              <Outlet />
            </Container>
          )}
        </Flex>
      </Box>
    </Box>
  );
}

MainLayout.propTypes = {
  withSideBar: PropTypes.bool,
};

export default MainLayout;
