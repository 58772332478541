import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './apps/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@descope/react-sdk';
import { ChakraProvider } from '@chakra-ui/react';
import { mainTheme } from './themes';
import 'typeface-roboto';
import { initGA } from './ga';



initGA()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID} baseUrl={process.env.REACT_APP_DESCOPE_BASE_URL}>
        <ChakraProvider theme={mainTheme} toastOptions={{defaultOptions : {position: 'top', duration: 5000, isClosable: true}}}>
            <App />
        </ChakraProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
