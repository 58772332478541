import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { deleteConnectorApi, getConnectorsApi } from 'api/connectorsApi';
import { getCustomerApi } from 'api/customerApi';
import ContentBox from 'components/ContentBox';
import DataTable from 'components/DataTable';
import { DestructiveDialog } from 'components/Dialog';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import StatusTag from 'components/StatusTag';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { NavLink, useParams } from 'react-router-dom';
import {
  CONNECTOR_INTEGRAION_META,
  CONNECTOR_INTEGRAION_TYPE,
  CONNECTOR_META_DATA,
  CONNECTOR_STATUS_META,
} from '../../../constants/connectorConstants';

const columnHelper = createColumnHelper();
const defaultSorting = [{ id: 'createdAtMillis', desc: true }];

const createColumns = (onRemoveConnector) => {
  const columns = [
    columnHelper.display({
      id: 'name',
      cell: (info) => {
        const connector = info.row.original;
        return <ConnectorInfo connector={connector} />;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('setupStatus', {
      cell: (info) => {
        const statusMeta = CONNECTOR_STATUS_META[info.getValue()];
        return <StatusTag level={statusMeta.level} label={statusMeta.text} />;
      },
      header: 'Status',
    }),
    columnHelper.accessor('createdAtMillis', {
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
      header: 'Created At',
    }),
    columnHelper.accessor('lastSyncMillis', {
      cell: (info) => {
        const connector = info.row.original;
        if (!CONNECTOR_INTEGRAION_META[connector.integrationType].hasLastSync) {
          return 'N/A';
        }
        return info.getValue() ? moment(info.getValue()).format('LLL') : ' - ';
      },
      header: 'Last Sync',
    }),
    columnHelper.display({
      id: 'remove',
      cell: (info) => {
        const connector = info.row.original;
        if (CONNECTOR_INTEGRAION_META[connector.integrationType].canRemove) {
          return (
            <Button
              variant={'link'}
              size={'sm'}
              color={'red.main'}
              onClick={() => onRemoveConnector(connector)}
            >
              Remove
            </Button>
          );
        }
      },
      enableSorting: false,
    }),
  ];

  return columns;
};

function ConnectorInfo({ connector }) {
  const { type, name, integrationType, plaidAccounts, logoBase64 } = connector;
  const isPlaid = integrationType === CONNECTOR_INTEGRAION_TYPE.PLAID;

  let { displayName, icon } = CONNECTOR_META_DATA[type] || {};

  if (name) {
    displayName = name;
  }

  const imgSrc = logoBase64 ? `data:image/jpeg;base64,${logoBase64}` : null;
  return (
    <>
      <Flex alignItems={'center'}>
        {imgSrc ? (
          <Image src={imgSrc} boxSize={8} mr={4} />
        ) : (
          <Icon as={icon} boxSize={8} mr={4} />
        )}
        <Box>
          <Text fontSize={'md'} ml={'10px'} m={0}>
            {displayName}
          </Text>
        </Box>
      </Flex>
      {isPlaid && (
        <Box ml={12}>
          {plaidAccounts.map((account) => (
            <Box key={account.id} fontSize={'xs'} color={'gray.main'}>
              {account.name} - {account.mask}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

function CustomerProfile() {
  const [loading, setLoading] = useState(true);
  const [loadingConnectors, setLoadingConnectors] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [connectors, setConnectors] = useState([]);
  const [columns, setColumns] = useState([]);
  const [connectorToRemove, setConnectorToRemove] = useState(null);
  const { customerId } = useParams();
  const toast = useToast();

  useEffect(() => {
    loadData();
    setColumns(createColumns(onRemoveConnectorClick));
  }, []);

  const loadData = () => {
    const allPromise = Promise.all([
      getCustomerApi(customerId),
      getConnectorsApi(customerId),
    ]);

    allPromise
      .then(([_customer, _connectorsResponseData]) => {
        setCustomer(_customer);
        handleConnectorsApiResult(_connectorsResponseData);
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Error loading customer',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadConnectors = async () => {
    setLoadingConnectors(true);
    try {
      const data = await getConnectorsApi(customerId);
      handleConnectorsApiResult(data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error loading connectors',
        status: 'error',
      });
    } finally {
      setLoadingConnectors(false);
    }
  };

  const handleConnectorsApiResult = (data) => {
    const { connectors, failedIntegrations } = data;
    setConnectors(connectors);
    if (failedIntegrations?.length > 0) {
      toast({
        title: 'Error',
        description: `Error loading connectors from ${failedIntegrations.join(
          ', '
        )} `,
        status: 'error',
      });
    }
  };

  const onRemoveConnectorClick = (connector) => {
    setConnectorToRemove(connector);
  };

  const onRemoveConnectorClose = () => {
    setConnectorToRemove(null);
  };

  const onRemoveConnector = async () => {
    try {
      setLoadingConnectors(true);
      await deleteConnectorApi(
        customer.id,
        connectorToRemove.type,
        connectorToRemove.id
      );
      toast({
        title: 'Success',
        description: 'Connector removed successfully',
        status: 'success',
      });
      loadConnectors();
    } catch (error) {
      setLoadingConnectors(false);
      toast({
        title: 'Error',
        description: 'Error removing connector',
        status: 'error',
      });
    }
  };

  if (loading)
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );

  if (!customer) {
    return null;
  }

  return (
    <>
      <ContentBox>
        <Flex align={'center'}>
          <Flex>
            <Link as={NavLink} to={'/customers'}>
              <ChevronLeftIcon boxSize={12} color={'black.main'} ml={-4} />
            </Link>
            <Box>
              <Heading as={'h2'}>{customer.companyName}</Heading>
              <Text fontSize={'sm'} color={'gray.main'} ml={2}>{customer.id}</Text>
            </Box>
          </Flex>
          <Box ml={12}>
            {customer.createdAt && (
              <Box>
                Created At:{' '}
                <b>
                  <Moment format={'lll'}>{customer.createdAt}</Moment>
                </b>
              </Box>
            )}
            {customer.lastLoginAt && (
              <Box mt={2}>
                Last Login:{' '}
                <b>
                  <Moment format={'lll'}>{customer.lastLoginAt}</Moment>
                </b>
              </Box>
            )}
          </Box>
        </Flex>
      </ContentBox>
      <ContentBox mt={4} title={'Connectors'}>
        <Box mt={4}>
          <OverlaySpinnerWrapper show={loadingConnectors}>
            {connectors.length === 0 ? (
              <Center>
                <Text fontSize={'xl'}>No connectors have been set up yet</Text>
              </Center>
            ) : (
              <DataTable
                columns={columns}
                data={connectors}
                defaultSorting={defaultSorting}
              />
            )}
          </OverlaySpinnerWrapper>
        </Box>
      </ContentBox>
      <DestructiveDialog
        header={'Remove Plaid Item'}
        body={
          connectorToRemove
            ? `Are you sure you want to remove the Plaid item for ${connectorToRemove.name} ?`
            : null
        }
        deleteButtonText={'Remove'}
        show={!!connectorToRemove}
        onDelete={onRemoveConnector}
        onDialogClose={onRemoveConnectorClose}
      />
    </>
  );
}

export default CustomerProfile;
