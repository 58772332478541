import PaypalIcon from 'components/icons/PaypalIcon';
import { BsBank2 } from 'react-icons/bs';
import AppStoreIcon from '../components/icons/AppStoreIcon';
import AppsflyerIcon from '../components/icons/AppsflyerIcon';
import FacebookIcon from '../components/icons/FacebookIcon';
import GooglePlayIcon from '../components/icons/GooglePlayIcon';
import InstagramIcon from '../components/icons/InstagramIcon';
import QuickbooksIcon from '../components/icons/QuickbooksIcon';
import ShopifyIcon from '../components/icons/ShopifyIcon';
import StripeIcon from '../components/icons/StripeIcon';
import XeroIcon from '../components/icons/XeroIcon';

export const CONNECTOR_TYPE = {
  PLAID: 'plaid',
  APPSTORE: 'appStore',
  GOOGLEPLAY: 'googlePlay',
  SHOPIFY: 'shopify',
  APPSFLYER: 'appsFlyer',
  QUICKBOOKS: 'quickbooks',
  STRIPE: 'stripe',
  XERO: 'xero',
  SHOPIFY_PARTNER: 'shopifyPartner',
  FACEBOOK_ADS: 'facebookAds',
  INSTAGRAM_BUSINESS: 'instagramBusiness',
  PAYPAL: 'paypal',
};

export const CONNECTOR_INTEGRAION_TYPE = {
  SHOPIFY_PARTNER: 'shopifyPartner',
  FIVETRAN: 'fivetran',
  PLAID: 'plaid',
  CODAT: 'codat',
};

export const CONNECTOR_STATUS = {
  INCOMPLETE: 'incomplete',
  CONNECTED: 'connected',
  BROKEN: 'broken',
};

export const CONNECTOR_STATUS_META = {
  [CONNECTOR_STATUS.INCOMPLETE]: {
    level: 'warning',
    text: 'Incomplete',
  },
  [CONNECTOR_STATUS.CONNECTED]: {
    level: 'success',
    text: 'Connected',
  },
  [CONNECTOR_STATUS.BROKEN]: {
    level: 'error',
    text: 'Broken',
  },
};

export const CONNECTOR_INTEGRAION_META = {
  [CONNECTOR_INTEGRAION_TYPE.PLAID]: {
    canRemove: true,
    hasLastSync: false,
  },
  [CONNECTOR_INTEGRAION_TYPE.FIVETRAN]: {
    hasLastSync: true,
  },
  [CONNECTOR_INTEGRAION_TYPE.SHOPIFY_PARTNER]: {
    hasLastSync: true,
  },
  [CONNECTOR_INTEGRAION_TYPE.CODAT]: {
    hasLastSync: true,
  },
};

export const CONNECTOR_INTEGRATION_MAPPING = {
  [CONNECTOR_TYPE.PLAID]: CONNECTOR_INTEGRAION_TYPE.PLAID,
  [CONNECTOR_TYPE.APPSTORE]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.GOOGLEPLAY]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.SHOPIFY]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.APPSFLYER]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  // [CONNECTOR_TYPE.QUICKBOOKS]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.QUICKBOOKS]: CONNECTOR_INTEGRAION_TYPE.CODAT,
  [CONNECTOR_TYPE.STRIPE]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.XERO]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.SHOPIFY_PARTNER]: CONNECTOR_INTEGRAION_TYPE.SHOPIFY_PARTNER,
  [CONNECTOR_TYPE.FACEBOOK_ADS]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.INSTAGRAM_BUSINESS]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
  [CONNECTOR_TYPE.PAYPAL]: CONNECTOR_INTEGRAION_TYPE.FIVETRAN,
};

export const CONNECTOR_META_DATA = {
  [CONNECTOR_TYPE.PLAID]: {
    displayName: 'Bank Account',
    icon: BsBank2,
  },
  [CONNECTOR_TYPE.APPSTORE]: {
    displayName: 'App Store',
    icon: AppStoreIcon,
  },
  [CONNECTOR_TYPE.GOOGLEPLAY]: {
    displayName: 'Google Play',
    icon: GooglePlayIcon,
  },
  [CONNECTOR_TYPE.SHOPIFY]: {
    displayName: 'Shopify',
    icon: ShopifyIcon,
  },
  [CONNECTOR_TYPE.APPSFLYER]: {
    displayName: 'AppsFlyer',
    icon: AppsflyerIcon,
  },
  [CONNECTOR_TYPE.QUICKBOOKS]: {
    displayName: 'QuickBooks',
    icon: QuickbooksIcon,
  },
  [CONNECTOR_TYPE.STRIPE]: {
    displayName: 'Stripe',
    icon: StripeIcon,
  },
  [CONNECTOR_TYPE.XERO]: {
    displayName: 'Xero',
    icon: XeroIcon,
  },
  [CONNECTOR_TYPE.SHOPIFY_PARTNER]: {
    displayName: 'Shopify Partner',
    icon: ShopifyIcon,
  },
  [CONNECTOR_TYPE.FACEBOOK_ADS]: {
    displayName: 'Facebook Ads',
    icon: FacebookIcon,
  },
  [CONNECTOR_TYPE.INSTAGRAM_BUSINESS]: {
    displayName: 'Instagram Business',
    icon: InstagramIcon,
  },
  [CONNECTOR_TYPE.PAYPAL]: {
    displayName: 'PayPal',
    icon: PaypalIcon,
  },
};
