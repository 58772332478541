import { Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import OnBoardingLayout from '../layouts/OnBoardingLayout';
import { ProtectedRoute } from './RedirectRoutes';

import HomeLender from '../pages/Lender/HomeLender';

function LenderApp({ loginComponent, isAuthenticated }) {

  return (
    <Routes>
      !isAuthenticated && (
      <Route element={<OnBoardingLayout />}>
        <Route index path="/login" element={loginComponent} />
      </Route>
      );
      <Route
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<HomeLender />} />
      </Route>
      ;
    </Routes>
  );
}

export default LenderApp;
