import { extendTheme } from '@chakra-ui/react';
import { FormLabelTheme } from './FormLabel';
import { InputTheme } from './Input';
import { HeadingTheme } from './Heading';
import { ButtonTheme } from './Button';
import { SpinnerTheme } from './Spinner';
import { CheckboxTheme } from './Checkbox';
import { LinkTheme } from './Link';
import { WhiteBoxTheme } from './WhiteBox';
import { NumberInputTheme } from './NumberInput';
import { ToolipTheme } from './Tooltip';

export const mainTheme = extendTheme({
  colors: {
    green: {
      100: '#C9FD37',
      200: '#10DC49',
    },
    red: {
      main: '#FC8181',
    },
    pink: {
      main: '#F322F8',
    },
    transparent: {
      50: '#f8f8f800',
      100: '#C9FD3700',
    },
    black: {
      main: '#141320',
    },
    gray: {
      50: '#EFF7FF',
      100: '#E0E8F0',
      200: '#3B4758',
      main: '#707882',
      border: '#EEF0F4',
    },
    primary: {
      main: '#2084F9',
      link: '#319DFF',
    },
    white: '#fff',
  },

  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
  shadows: {
    box: '0px 4px 20px rgba(170, 169, 184, 0.1)',
  },
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Roboto',
        minHeight: '100vh',
        color: 'black.main',
      },
      a: {
        color: 'primary.link',
      },
    },
  },
  components: {
    FormLabel: FormLabelTheme,
    Input: InputTheme,
    Heading: HeadingTheme,
    Button: ButtonTheme,
    Spinner: SpinnerTheme,
    Checkbox: CheckboxTheme,
    Link: LinkTheme,
    WhiteBox: WhiteBoxTheme,
    NumberInput: NumberInputTheme,
    Tooltip: ToolipTheme,
  },
});
