import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children || <Outlet />;
};

export const RedirectOnBoardingRoute = ({ customer, children }) => {
  if (customer) {
    if (customer.approvedTerms) {
      return children;
    } else {
      return <Navigate to="/onboarding" replace />;
    }
  }
  return children || <Outlet />;
};
