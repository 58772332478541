import axios from 'axios';

export const getAllCustomersApi = async () => {
  const response = await axios.get(`/admin/customer`);
  return response.data;
};

export const getPerforamceCustomerListApi = async () => {
  const response = await axios.get(`/admin/performance-customer-list`);
  return response.data;
};

export const getFundingCustomerListApi = async () => {
  const response = await axios.get(`/admin/funding-customer-list`);
  return response.data;
};

export const getCustomerFundingApi = async (customerId) => {
  const response = await axios.get(`/admin/customer/${customerId}/funding`);
  return response.data;
};

export const getCustomerFundingSimApi = async (customerId, data) => {
  const response = await axios.post(
    `/admin/customer/${customerId}/funding-sim`,
    data
  );
  return response.data;
};

export const getCustomerFundingLossSimApi = async (customerId, data) => {
  const response = await axios.post(
    `/admin/customer/${customerId}/funding-loss-sim`,
    data
  );
  return response.data;
};

export const createUserAndCustomerApi = async ({ email, companyName }) => {
  const response = await axios.post(`/admin/customer/user`, {
    email,
    companyName,
  });
  return response.data;
};

export const createUserForExistingCustomerApi = async ({
  customerId,
  email,
}) => {
  const response = await axios.post(`/admin/customer/${customerId}/user`, {
    email,
  });
  return response.data;
};

export const getBankingTransactionsApi = async () => {
  const response = await axios.get(`/admin/banking/transactions`);
  return response.data;
}

export const getSofrApi = async () => {
  const response = await axios.get(`/admin/sofr`);
  return response.data;
}
