import { useEffect, useState, useContext } from 'react';
import { LenderContext } from '../../context/lenderContext';
import LenderPerformance from '../../components/LenderPerformance';
import { AbsoluteCenter, Heading, Spinner } from '@chakra-ui/react';
import WhiteBox from '../../components/WhiteBox';

function HomeLender() {
  const { lender, isLenderLoading } = useContext(LenderContext);

  if (isLenderLoading || !lender  ) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return <LenderPerformance lenderId={lender.id} />;
}

export default HomeLender;
