import axios from 'axios';

export const getLenderApi = async () => {
  const response = await axios.get(`/lender`);
  return response.data;
};

export const getLenderPerformanceSummaryApi = async (lenderId) => {
  const response = await axios.get(`/lender/${lenderId}/performance-summary`);
  return response.data;
};

export const getLenderTransactionsApi = async (lenderId) => {
  const response = await axios.get(`/lender/${lenderId}/transactions`);
  return response.data;
};

export const getLenderPerformanceMockApi = async () => {
  const response = await axios.get(`/lender/performance-mock`);
  return response.data;
};
