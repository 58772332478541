import React from "react";

import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Progress,
  SimpleGrid,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const HomeMainSection = () => {
  return (
    <>
      <section>
        <Box lineHeight={{ base: "7", lg: "42px" }} mb="5">
          <Text
            fontSize={{ base: "24px", md: "36", lg: "36" }}
            color="black.main"
            fontWeight="500"
          >
            👋 Hi Adam!
          </Text>
          <Text fontSize={{ base: "16px", lg: "20px" }} color="gray.main">
            Your conversion rate went up 15%. Amazing! We can now help you grow
            faster with additional funding of up to $10k 🎉
          </Text>
        </Box>
        <Box
          bg="white"
          boxShadow="box"
          p="6"
          borderRadius="20"
          mb={{ base: "10", md: "100" }}
        >
          <Box
            display={{ md: "flex" }}
            gap="8"
            alignItems="center"
            borderBottom="0.5px"
            borderColor={"#EFF7FF"}
            borderStyle={"solid"}
            pb="5"
          >
            <Image
              alt=""
              src="./images/get-funding.png"
              mb={{ base: "2", lg: "0" }}
              w="70px"
              h="70px"
            ></Image>
            <Box w="93%">
              <Text
                fontWeight={700}
                fontSize={{ base: "14px", lg: "16px" }}
                color="black.main"
                mb="1"
                lineHeight="19px"
              >
                Get additional funding
              </Text>
              <Text
                color="gray.main"
                fontSize={{ base: "12px", lg: "14px" }}
                mb="1"
              >
                Connect to your AppsFlyer account in few steps and get
                additional funding up to $1,000{" "}
              </Text>
              <Box
                display={{ base: "grid", md: "flex" }}
                gap={2}
                alignItems="center"
              >
                <Text
                  fontWeight={500}
                  fontSize={{ base: "14px", lg: "16px" }}
                  color="primary.main"
                  lineHeight="19px"
                >
                  Get more funding
                </Text>
                <Spacer />
                <Flex alignItems={"center"} gap="1">
                  <Button
                    border="1px"
                    borderColor="gray.100"
                    borderRadius="full"
                    py="5px"
                    px="4"
                    bg="transparent"
                    fontSize={{ base: "12px", lg: "14px" }}
                    fontWeight={400}
                    color="black.main"
                  >
                    Get more funding
                  </Button>
                  <Flex
                    w={8}
                    h={8}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                  >
                    <svg
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00004 13.6667C1.54171 13.6667 1.14949 13.5037 0.823374 13.1775C0.496708 12.8509 0.333374 12.4584 0.333374 12C0.333374 11.5417 0.496708 11.1492 0.823374 10.8225C1.14949 10.4964 1.54171 10.3334 2.00004 10.3334C2.45837 10.3334 2.85087 10.4964 3.17754 10.8225C3.50365 11.1492 3.66671 11.5417 3.66671 12C3.66671 12.4584 3.50365 12.8509 3.17754 13.1775C2.85087 13.5037 2.45837 13.6667 2.00004 13.6667ZM2.00004 8.66671C1.54171 8.66671 1.14949 8.50337 0.823374 8.17671C0.496708 7.8506 0.333374 7.45837 0.333374 7.00004C0.333374 6.54171 0.496708 6.14921 0.823374 5.82254C1.14949 5.49643 1.54171 5.33337 2.00004 5.33337C2.45837 5.33337 2.85087 5.49643 3.17754 5.82254C3.50365 6.14921 3.66671 6.54171 3.66671 7.00004C3.66671 7.45837 3.50365 7.8506 3.17754 8.17671C2.85087 8.50337 2.45837 8.66671 2.00004 8.66671ZM2.00004 3.66671C1.54171 3.66671 1.14949 3.50337 0.823374 3.17671C0.496708 2.8506 0.333374 2.45837 0.333374 2.00004C0.333374 1.54171 0.496708 1.14948 0.823374 0.823374C1.14949 0.496707 1.54171 0.333374 2.00004 0.333374C2.45837 0.333374 2.85087 0.496707 3.17754 0.823374C3.50365 1.14948 3.66671 1.54171 3.66671 2.00004C3.66671 2.45837 3.50365 2.8506 3.17754 3.17671C2.85087 3.50337 2.45837 3.66671 2.00004 3.66671Z"
                        fill="#707882"
                      />
                    </svg>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box
            display={{ md: "flex" }}
            gap="8"
            alignItems="center"
            borderBottom="0.5px"
            borderColor={"#EFF7FF"}
            borderStyle={"solid"}
            py="5"
          >
            <Image
              alt=""
              src="./images/optimize-app.png"
              mb={{ base: "2", lg: "0" }}
              w="70px"
              h="70px"
            ></Image>
            <Box w="93%">
              <Text
                fontWeight={700}
                fontSize={{ base: "14px", lg: "16px" }}
                color="black.main"
                mb="1"
                lineHeight="19px"
              >
                Optimize your AppStore page
              </Text>
              <Text
                color="gray.main"
                fontSize={{ base: "12px", lg: "14px" }}
                mb="1"
              >
                Use our partner App Radar to optimize your App Store page and
                increase your download rates
              </Text>
              <Box
                display={{ base: "grid", md: "flex" }}
                gap={2}
                alignItems="center"
              >
                <Text
                  fontWeight={500}
                  fontSize={{ base: "14px", lg: "16px" }}
                  color="primary.main"
                  lineHeight="19px"
                >
                  Go to app
                </Text>
                <Spacer />
                <Flex alignItems={"center"} gap="1">
                  <Button
                    border="1px"
                    borderColor="gray.100"
                    borderRadius="full"
                    py="5px"
                    px="4"
                    bg="transparent"
                    fontSize={{ base: "12px", lg: "14px" }}
                    fontWeight={400}
                    color="black.main"
                  >
                    Increase downloads
                  </Button>
                  <Flex
                    w={8}
                    h={8}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                  >
                    <svg
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00004 13.6667C1.54171 13.6667 1.14949 13.5037 0.823374 13.1775C0.496708 12.8509 0.333374 12.4584 0.333374 12C0.333374 11.5417 0.496708 11.1492 0.823374 10.8225C1.14949 10.4964 1.54171 10.3334 2.00004 10.3334C2.45837 10.3334 2.85087 10.4964 3.17754 10.8225C3.50365 11.1492 3.66671 11.5417 3.66671 12C3.66671 12.4584 3.50365 12.8509 3.17754 13.1775C2.85087 13.5037 2.45837 13.6667 2.00004 13.6667ZM2.00004 8.66671C1.54171 8.66671 1.14949 8.50337 0.823374 8.17671C0.496708 7.8506 0.333374 7.45837 0.333374 7.00004C0.333374 6.54171 0.496708 6.14921 0.823374 5.82254C1.14949 5.49643 1.54171 5.33337 2.00004 5.33337C2.45837 5.33337 2.85087 5.49643 3.17754 5.82254C3.50365 6.14921 3.66671 6.54171 3.66671 7.00004C3.66671 7.45837 3.50365 7.8506 3.17754 8.17671C2.85087 8.50337 2.45837 8.66671 2.00004 8.66671ZM2.00004 3.66671C1.54171 3.66671 1.14949 3.50337 0.823374 3.17671C0.496708 2.8506 0.333374 2.45837 0.333374 2.00004C0.333374 1.54171 0.496708 1.14948 0.823374 0.823374C1.14949 0.496707 1.54171 0.333374 2.00004 0.333374C2.45837 0.333374 2.85087 0.496707 3.17754 0.823374C3.50365 1.14948 3.66671 1.54171 3.66671 2.00004C3.66671 2.45837 3.50365 2.8506 3.17754 3.17671C2.85087 3.50337 2.45837 3.66671 2.00004 3.66671Z"
                        fill="#707882"
                      />
                    </svg>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box
            display={{ md: "flex" }}
            gap="8"
            alignItems="center"
            borderBottom="0.5px"
            borderColor={"#EFF7FF"}
            borderStyle={"solid"}
            py="5"
          >
            <Image
              alt=""
              src="./images/grow-tiktok.png"
              mb={{ base: "2", lg: "0" }}
              w="70px"
              h="70px"
            ></Image>
            <Box w="93%">
              <Text
                fontWeight={700}
                fontSize={{ base: "14px", lg: "16px" }}
                color="black.main"
                mb="1"
                lineHeight="19px"
              >
                Grow with TikTok
              </Text>
              <Text
                color="gray.main"
                fontSize={{ base: "12px", lg: "14px" }}
                mb="1"
              >
                Seems like you have more room to grow with the new TikTok
                campaign. Get $500 when spending $5,000 on TikTok
              </Text>
              <Box
                display={{ base: "grid", md: "flex" }}
                gap={2}
                alignItems="center"
              >
                <Text
                  fontWeight={500}
                  fontSize={{ base: "14px", lg: "16px" }}
                  color="primary.main"
                  lineHeight="19px"
                >
                  Go to app
                </Text>
                <Spacer />
                <Flex alignItems={"center"} gap="1">
                  <Button
                    border="1px"
                    borderColor="gray.100"
                    borderRadius="full"
                    py="5px"
                    px="4"
                    bg="transparent"
                    fontSize={{ base: "12px", lg: "14px" }}
                    fontWeight={400}
                    color="black.main"
                  >
                    Increase downloads
                  </Button>
                  <Flex
                    w={8}
                    h={8}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                  >
                    <svg
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00004 13.6667C1.54171 13.6667 1.14949 13.5037 0.823374 13.1775C0.496708 12.8509 0.333374 12.4584 0.333374 12C0.333374 11.5417 0.496708 11.1492 0.823374 10.8225C1.14949 10.4964 1.54171 10.3334 2.00004 10.3334C2.45837 10.3334 2.85087 10.4964 3.17754 10.8225C3.50365 11.1492 3.66671 11.5417 3.66671 12C3.66671 12.4584 3.50365 12.8509 3.17754 13.1775C2.85087 13.5037 2.45837 13.6667 2.00004 13.6667ZM2.00004 8.66671C1.54171 8.66671 1.14949 8.50337 0.823374 8.17671C0.496708 7.8506 0.333374 7.45837 0.333374 7.00004C0.333374 6.54171 0.496708 6.14921 0.823374 5.82254C1.14949 5.49643 1.54171 5.33337 2.00004 5.33337C2.45837 5.33337 2.85087 5.49643 3.17754 5.82254C3.50365 6.14921 3.66671 6.54171 3.66671 7.00004C3.66671 7.45837 3.50365 7.8506 3.17754 8.17671C2.85087 8.50337 2.45837 8.66671 2.00004 8.66671ZM2.00004 3.66671C1.54171 3.66671 1.14949 3.50337 0.823374 3.17671C0.496708 2.8506 0.333374 2.45837 0.333374 2.00004C0.333374 1.54171 0.496708 1.14948 0.823374 0.823374C1.14949 0.496707 1.54171 0.333374 2.00004 0.333374C2.45837 0.333374 2.85087 0.496707 3.17754 0.823374C3.50365 1.14948 3.66671 1.54171 3.66671 2.00004C3.66671 2.45837 3.50365 2.8506 3.17754 3.17671C2.85087 3.50337 2.45837 3.66671 2.00004 3.66671Z"
                        fill="#707882"
                      />
                    </svg>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Box>
          <Box display={{ md: "flex" }} gap="8" alignItems="center" pt="5">
            <Image
              alt=""
              src="./images/top-of-week.png"
              mb={{ base: "2", lg: "0" }}
              w="70px"
              h="70px"
            ></Image>
            <Box w="93%">
              <Text
                fontWeight={700}
                fontSize={{ base: "14px", lg: "16px" }}
                color="black.main"
                mb="1"
                lineHeight="19px"
              >
                Tip of the week
              </Text>
              <Text
                color="gray.main"
                fontSize={{ base: "12px", lg: "14px" }}
                mb="1"
              >
                5 Ways to Lower Your Cost Per Action (CPA)
              </Text>
              <Box
                display={{ base: "grid", md: "flex" }}
                gap={2}
                alignItems="center"
              >
                <Text
                  fontWeight={500}
                  fontSize={{ base: "14px", lg: "16px" }}
                  color="primary.main"
                  lineHeight="19px"
                >
                  Learn more
                </Text>
                <Spacer />
                <Flex alignItems={"center"} gap="1">
                  <Button
                    border="1px"
                    borderColor="gray.100"
                    borderRadius="full"
                    py="5px"
                    px="4"
                    bg="transparent"
                    fontSize={{ base: "12px", lg: "14px" }}
                    fontWeight={400}
                    color="black.main"
                  >
                    Reduce costs{" "}
                  </Button>
                  <Flex
                    w={8}
                    h={8}
                    alignItems={"center"}
                    justifyContent={"center"}
                    cursor={"pointer"}
                  >
                    <svg
                      width="4"
                      height="14"
                      viewBox="0 0 4 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.00004 13.6667C1.54171 13.6667 1.14949 13.5037 0.823374 13.1775C0.496708 12.8509 0.333374 12.4584 0.333374 12C0.333374 11.5417 0.496708 11.1492 0.823374 10.8225C1.14949 10.4964 1.54171 10.3334 2.00004 10.3334C2.45837 10.3334 2.85087 10.4964 3.17754 10.8225C3.50365 11.1492 3.66671 11.5417 3.66671 12C3.66671 12.4584 3.50365 12.8509 3.17754 13.1775C2.85087 13.5037 2.45837 13.6667 2.00004 13.6667ZM2.00004 8.66671C1.54171 8.66671 1.14949 8.50337 0.823374 8.17671C0.496708 7.8506 0.333374 7.45837 0.333374 7.00004C0.333374 6.54171 0.496708 6.14921 0.823374 5.82254C1.14949 5.49643 1.54171 5.33337 2.00004 5.33337C2.45837 5.33337 2.85087 5.49643 3.17754 5.82254C3.50365 6.14921 3.66671 6.54171 3.66671 7.00004C3.66671 7.45837 3.50365 7.8506 3.17754 8.17671C2.85087 8.50337 2.45837 8.66671 2.00004 8.66671ZM2.00004 3.66671C1.54171 3.66671 1.14949 3.50337 0.823374 3.17671C0.496708 2.8506 0.333374 2.45837 0.333374 2.00004C0.333374 1.54171 0.496708 1.14948 0.823374 0.823374C1.14949 0.496707 1.54171 0.333374 2.00004 0.333374C2.45837 0.333374 2.85087 0.496707 3.17754 0.823374C3.50365 1.14948 3.66671 1.54171 3.66671 2.00004C3.66671 2.45837 3.50365 2.8506 3.17754 3.17671C2.85087 3.50337 2.45837 3.66671 2.00004 3.66671Z"
                        fill="#707882"
                      />
                    </svg>
                  </Flex>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>
    </>
  );
};

export default HomeMainSection;
