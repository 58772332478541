import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { getBankingTransactionsApi } from 'api/adminApi';
import DataTable from 'components/DataTable';
import { Number } from 'components/Number';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor('accountNumber', {
    cell: (info) => info.getValue(),
    header: 'Account Number',
  }),
  columnHelper.accessor('accountName', {
    cell: (info) => info.getValue(),
    header: 'Account Name',
  }),
  columnHelper.accessor('dateTime', {
    cell: (info) => moment(info.getValue()).format('LL'),
    header: 'Date',
  }),
  columnHelper.accessor('amount', {
    cell: (info) => <Number value={info.getValue()} />,
    header: 'Amount',
  }),
  columnHelper.accessor('bankDescription', {
    cell: (info) => info.getValue(),
    header: 'Bank Description',
    meta: {
      maxWidth: 400,
    },
  }),
  columnHelper.accessor('memo', {
    cell: (info) => info.getValue(),
    header: 'Memo',
    meta: {
      maxWidth: 400,
    },
  }),
];

const csvHeaders = [
  { label: 'Account Number', key: 'accountNumber' },
  {
    label: 'Account Name',
    key: 'accountName',
  },
  {
    label: 'Date',
    key: 'dateTime',
  },
  {
    label: 'Amount',
    key: 'amount',
  },
  {
    label: 'Bank Description',
    key: 'bankDescription',
  },
  {
    label: 'Memo',
    key: 'memo',
  },
];

function Banking() {
  const [transactions, setTransactions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]); // [
  const toast = useToast();

  useEffect(() => {
    loadTransactions();
  }, []);

  const loadTransactions = () => {
    setLoading(true);
    getBankingTransactionsApi()
      .then((_transactions) => {
        setTransactions(_transactions);
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'Failed to load transactions',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterTransactions = (_transactions) => {
    if (!searchValue || searchValue.length < 3) {
      return _transactions;
    }

    return _transactions.filter((transaction) => {
      return (
        transaction.accountNumber
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        transaction.accountName
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        (transaction.bankDescription &&
          transaction.bankDescription
            .toLowerCase()
            .includes(searchValue.toLowerCase())) ||
        (transaction.memo &&
          transaction.memo.toLowerCase().includes(searchValue.toLowerCase()))
      );
    });
  };

  const generateCsvData = (event, done) => {
    const _csvData = filterTransactions(transactions).map((transaction) => {
      return {
        accountNumber: transaction.accountNumber,
        accountName: transaction.accountName,
        dateTime: moment(transaction.dateTime).format('LL'),
        amount: transaction.amount,
        bankDescription: transaction.bankDescription,
        memo: transaction.memo,
      };
    });

    setCsvData(_csvData);
    done(true);
  };

  return (
    <>
      <Flex justify={'space-between'}>
        <InputGroup mb={6} w={300}>
          {' '}
          <Input
            type={'search'}
            placeholder={'Search'}
            onChange={(event) => setSearchValue(event.target.value)}
            value={searchValue}
          />
          <InputRightElement>
            <SearchIcon />
          </InputRightElement>
        </InputGroup>
        <Button leftIcon={<DownloadIcon />}>
          <CSVLink
            style={{ color: 'white' }}
            data={csvData}
            headers={csvHeaders}
            filename="twelve-data-banking.csv"
            onClick={generateCsvData}
            asyncOnClick={true}
          >
            Download CSV
          </CSVLink>
        </Button>
      </Flex>

      <Box minH={300} maxH={'calc(100vh - 380px)'} overflow={'auto'}>
        <OverlaySpinnerWrapper show={loading}>
          <DataTable
            data={filterTransactions(transactions)}
            columns={columns}
          />
        </OverlaySpinnerWrapper>
      </Box>
    </>
  );
}

export default Banking;
