import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import {
  Flex,
  Text,
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Button,
  Heading,
  Checkbox,
  RadioGroup,
  Radio,
  Stack,
  TableContainer,
  Thead,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from '../../../components/NumberInput';
import { Percentage, Currency } from '../../../components/Number';
import '../../../assets/scss/FundingSimForm.scss';
import { calcROAS, convertCpiToCac } from './fundingCalc';

const twelveFundingFields = [
  {
    label: 'Twelve Funding',
    name: 'twelveFunding.twelveMonthlyFunding',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'Twelve Fee',
    name: 'twelveFunding.twelveFee',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'RBF',
    name: 'twelveFunding.rbf',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'Number Of Tranches',
    name: 'twelveFunding.numberOfTranches',
    element: TwNumberInput,
  },
  {
    label: 'Grace Period',
    name: 'twelveFunding.gracePeriod',
    element: TwNumberInput,
  },
];

const companyInfoFields = [
  {
    label: 'Conversion Rate',
    name: 'companyInfo.conversionRate',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'σ (sd.)',
    name: 'companyInfo.conversionRateSdPct',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'CPI',
    name: 'companyInfo.cpi',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'σ (sd.)',
    name: 'companyInfo.cpiSdPct',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'CAC',
    name: 'companyInfo.cacAvg',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'σ (sd.)',
    name: 'companyInfo.cacSd',
    element: TwNumberInput,
  },
  {
    label: 'Opex',
    name: 'companyInfo.opex',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'σ (sd.)',
    name: 'companyInfo.opexSdPerc',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'ARPU',
    name: 'companyInfo.arpuAvg',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'σ (sd.)',
    name: 'companyInfo.arpuSd',
    element: TwNumberInput,
  },
  {
    label: 'Cash Balance',
    name: 'companyInfo.startBalance',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {},
  {
    label: 'Monthly Marketing',
    name: 'companyInfo.marketingMonthly',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'UA Marketing',
    name: 'companyInfo.marketingUAMonthly',
    element: CurrencyNumberInput,
    showPrefix: true,
  },
  {
    label: 'First Month Retention',
    name: 'companyInfo.firstMonthRetentionPct',
    element: PercentageNumberInput,
    showPrefix: true,
  },
  {
    label: 'σ (sd.)',
    name: 'companyInfo.firstMonthRetentionSdPct',
    element: PercentageNumberInput,
    showPrefix: true,
  },
];

function FundingSimForm({
  defaultValues,
  onSubmit,
  roasInterval,
  onRoasIntervalChange,
  isCac,
  onIsCacChange,
}) {
  
  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues,
  });

  const getCacFieldsToHide = () => {
    if (isCac) {
      return [
        'companyInfo.cpi',
        'companyInfo.cpiSdPct',
        'companyInfo.conversionRate',
        'companyInfo.conversionRateSdPct',
      ];
    }

    return ['companyInfo.cacAvg', 'companyInfo.cacSd'];
  };

  const onNumberInputChange = (name, value) => {
    setValue(name, value);
  };

  const onFormSubmit = (originalData) => {
    let newData = cloneDeep(originalData);

    if (!isCac) {
      const cacConversion = convertCpiToCac({
        cpi: newData.companyInfo.cpi,
        conversionRate: newData.companyInfo.conversionRate,
        cpiSdPct: newData.companyInfo.cpiSdPct,
      });
      newData.companyInfo.cacAvg = cacConversion.cacAvg;
      newData.companyInfo.cacSd = cacConversion.cacSd;
    }
    onSubmit(originalData, newData);
  };

  function RoasAnalysis() {
    const retentionPrediction = watch('companyInfo.retentionPrediction');
    if (!retentionPrediction) {
      return null;
    }

    const arpu = watch('companyInfo.arpuAvg');
    const cac = watch('companyInfo.cacAvg');
    const cpi = watch('companyInfo.cpi');
    const conversionRate = watch('companyInfo.conversionRate');
    const cpiSdPct = watch('companyInfo.cpiSdPct');
    const firstMonthRetentionPct = watch('companyInfo.firstMonthRetentionPct');

    const firstMonthRetetionRatio = firstMonthRetentionPct / retentionPrediction[1];
    let newRetentionPrediction = cloneDeep(retentionPrediction);
    newRetentionPrediction[1] = firstMonthRetentionPct;
    newRetentionPrediction.forEach((value, index) => {
      if (index > 1) {
        newRetentionPrediction[index] = value * firstMonthRetetionRatio;
      }
    });

    let newCac = cac;
    if (!isCac) {
      newCac = convertCpiToCac({
        cpi,
        conversionRate,
        cpiSdPct,
      }).cacAvg;
    }

    const {
      retentionPredictionVector,
      accAvgRevenueVector,
      roasVector,
      roas100Index,
    } = calcROAS({
      cac: newCac,
      arpu,
      retentionPrediction: newRetentionPrediction,
      isWeekly: roasInterval === 'weekly',
      isMonthly: roasInterval === 'monthly',
    });

    return (
      <>
        <Box>
          <Heading as="h4" size="sm" mb={4}>
            ROAS Analysis
          </Heading>
          <RadioGroup
            size={'sm'}
            value={roasInterval}
            onChange={onRoasIntervalChange}
          >
            <Stack spacing={5} direction="row" mb={3}>
              <Radio value={'monthly'}>Monthly</Radio>
              <Radio value={'weekly'}>Weekly</Radio>
            </Stack>
          </RadioGroup>
          <TableContainer bg={'gray.50'} p={4} borderRadius={6}>
            <Table class={'vectors-table'}>
              <Thead>
                <Tr>
                  <Th>{roasInterval === 'weekly' ? 'Week' : 'Month'}</Th>
                  {retentionPredictionVector.map((value, index) => {
                    return (
                      <Th key={index}>
                        <Text>{index + 1}</Text>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Retention</Td>
                  {retentionPredictionVector.map((value, index) => {
                    return (
                      <Td key={index}>
                        <Text>
                          <Percentage value={value} />
                        </Text>
                      </Td>
                    );
                  })}
                </Tr>
                <Tr>
                  <Td>Acc Avg Revenue</Td>
                  {accAvgRevenueVector.map((value, index) => {
                    return (
                      <Td key={index}>
                        <Text>
                          {isNaN(value) ? 'N/A' : <Currency value={value} />}
                        </Text>
                      </Td>
                    );
                  })}
                </Tr>
                <Tr>
                  <Td>ROAS</Td>
                  {roasVector.map((value, index) => {
                    return (
                      <Td
                        key={index}
                        bg={roas100Index == index ? 'green.100' : ''}
                      >
                        <Text>
                          {isNaN(value) ? 'N/A' : <Percentage value={value} />}
                        </Text>
                      </Td>
                    );
                  })}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Box mt={5}>
          <Text
            bg={'gray.50'}
            px={6}
            py={4}
            borderRadius={6}
            display={'inline'}
          >
            <span>ROAS 100% : </span>
            {roasInterval === 'weekly' ? 'Week' : 'Month'} #{roas100Index + 1}
          </Text>
        </Box>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={'funding-sim-form'}>
      <FormControl>
        <Flex justify={'space-between'} gap={100}>
          <Box>
            <Heading as={'h3'} size={'lg'} mb={8}>
              Funding Inputs
            </Heading>
            <SimpleGrid columns={2} spacing={6}>
              {twelveFundingFields.map((item, index) => {
                const Element = item.element;
                return (
                  <Box key={`${index}`}>
                    <FormLabel>{item.label}</FormLabel>
                    <Controller
                      control={control}
                      name={item.name}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Element
                          {...field}
                          showPrefix={item.showPrefix}
                          isDisabled={item.isDisabled}
                          onChange={(value) => {
                            onNumberInputChange(item.name, value);
                          }}
                        />
                      )}
                    />
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
          <Box>
            <Flex justify={'space-between'} align={'center'} mb={4}>
              <Heading as={'h3'} size={'lg'}>
                Customer Info
              </Heading>
              <Checkbox defaultChecked={true} isDisabled={true}>
                Normal Distribution
              </Checkbox>
            </Flex>
            <Flex justify={'space-between'} align={'center'} mb={4}>
              <RadioGroup size={'sm'} value={isCac} onChange={onIsCacChange}>
                <Stack spacing={5} direction="row">
                  <Radio value={true}>CAC</Radio>
                  <Radio value={false}>CPI</Radio>
                </Stack>
              </RadioGroup>
              <Text
                bg={'gray.50'}
                fontSize={'sm'}
                px={4}
                py={2}
                borderRadius={6}
              >
                Dataset Size: 31990
              </Text>
            </Flex>
            <SimpleGrid columns={2} spacing={6}>
              {companyInfoFields.map((item, index) => {
                if (!item.element) {
                  return <Box key={`twFunding${index}`}></Box>;
                }

                const Element = item.element;

                if (getCacFieldsToHide().includes(item.name)) {
                  return;
                }

                return (
                  <Box key={`${index}`}>
                    <FormLabel>{item.label}</FormLabel>
                    <Controller
                      control={control}
                      name={item.name}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Element
                          {...field}
                          showPrefix={item.showPrefix}
                          isDisabled={item.isDisabled}
                          onChange={(value) => {
                            onNumberInputChange(item.name, value);
                          }}
                        />
                      )}
                    />
                  </Box>
                );
              })}
            </SimpleGrid>
          </Box>
        </Flex>
        <Box mt={10}>
          <RoasAnalysis />
        </Box>
        <Flex justify={'flex-end'} align={'center'} mt={20}>
          <FormLabel>Number of Iterations</FormLabel>
          <Box w={100}>
            <Controller
              control={control}
              name={'numOfIterations'}
              rules={{ required: true }}
              render={({ field }) => (
                <TwNumberInput
                  {...field}
                  onChange={(value) => {
                    onNumberInputChange('numOfIterations', value);
                  }}
                />
              )}
            />
          </Box>
          <Button type="submit" ml={4}>
            Run Simulation
          </Button>
        </Flex>
      </FormControl>
    </form>
  );
}

export default FundingSimForm;
