import React, { createContext, useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';

import {
  getLenderApi,
} from '../api/lenderApi';

export const LenderContext = createContext();

const LenderProvider = ({ isAuthenticated, children }) => {
  const [lender, setLender] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      getLenderApi()
        .then((_lender) => {
          setLender(_lender);
        })
        .catch((error) => {
          toast({
            title: 'Error',
            description: 'Failed to load user.',
            status: 'error',
          });
          throw error;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <LenderContext.Provider
      value={{
        lender,
        isLenderLoading: isLoading,
      }}
    >
      {children}
    </LenderContext.Provider>
  );
};

export default LenderProvider;
