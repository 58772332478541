import { AbsoluteCenter, Spinner, Heading, Text, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import WhiteBox from './WhiteBox';

function ContentBox(props) {
  const { children, loading, title, subTitle, ...rest } = props;
  
  return (
    // <Box
    //   axis="both"
    //   maxW={width || 480}
    //   minW={minWidth || 320}
    //   minH={minHeight}
    //   paddingX={6}
    //   paddingY={10}
    //   bgColor={'white'}
    //   borderRadius={12}
    //   boxShadow={'box'}
    //   position={'relative'}
    // >
    <WhiteBox {...rest}>
      {loading ? (
        <AbsoluteCenter>
          <Spinner />
        </AbsoluteCenter>
      ) : (
        <>
          {title && (
            <Heading as="h1" size="l">
              {title}
            </Heading>
          )}
          {subTitle && (
            <Text color="gray.main" fontSize="sm" my={10}>
              {subTitle}
            </Text>
          )}
          {children}
        </>
      )}
    </WhiteBox>
  );
}

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  minHeight: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ContentBox;
