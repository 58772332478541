import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CustomerContext } from '../context/customerContext';
import MainLayout from '../layouts/MainLayout';
import OnBoardingLayout from '../layouts/OnBoardingLayout';
import Connectors from '../pages/Connectors/Connectors';
import Home from '../pages/Home';
import OnBoardingForm from '../pages/OnBoarding/OnBoardingForm';
import TermsAndPrivacy from '../pages/TermsAndPrivacy';
import TwelveConnect from '../pages/TwelveConnect';
import { ProtectedRoute, RedirectOnBoardingRoute } from './RedirectRoutes';

function CustomerApp({ loginComponent, isAuthenticated }) {
  const { customer, isCustomerLoading, loginCustomer } =
    useContext(CustomerContext);

  return (
    <Routes>
      {!isAuthenticated && (
        <Route element={<OnBoardingLayout />}>
          <Route
            path="/login"
            element={React.cloneElement(loginComponent, {
              onLoginSuccess: loginCustomer,
            })}
          />
        </Route>
      )}
      <Route
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <OnBoardingLayout />
          </ProtectedRoute>
        }
      >
        <Route path="/onboarding" element={<OnBoardingForm />} />
        <Route
          path="/privacy-policy"
          element={<TermsAndPrivacy isPrivacy={true} />}
        />
        <Route
          path="/terms-of-use"
          element={<TermsAndPrivacy isTerms={true} />}
        />
      </Route>

      <Route
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <RedirectOnBoardingRoute customer={customer}>
              <MainLayout loading={isCustomerLoading || !customer} />
            </RedirectOnBoardingRoute>
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Navigate to="/connectors" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/twelve-connect" element={<TwelveConnect />} />
        <Route path="/connectors" index element={<Connectors />} />
      </Route>
    </Routes>
  );
}

export default CustomerApp;
