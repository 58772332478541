import { Descope } from '@descope/react-sdk';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { AbsoluteCenter } from '@chakra-ui/react';
import ContentBox from '../components/ContentBox';

function Login({ loading, onLoginSuccess }) {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const onSuccess = useCallback((event) => {
    if (event.detail?.refreshJwt) {
      const cookies = new Cookies();
      const domain =
        window.location.hostname === 'localhost'
          ? ''
          : process.env.REACT_APP_API_URL.split('//')[1];

      cookies.set('DSR', event.detail.refreshJwt, {
        domain,
        path: '/',
        sameSite: 'none',
        secure: true,
      });
    }

    onLoginSuccess && onLoginSuccess();
    navigate('/');
  }, []);

  const onError = useCallback(() => {
    setErrorMessage('Something went wrong');
  }, [setErrorMessage]);

  return (
    <AbsoluteCenter mt={[50, 0]}>
      <ContentBox loading={loading} minH={290} w={[320, 480]} px={[4, 6]}>
        <>
          <Descope
            flowId={'sign-in'}
            // redirectUrl={`${window.location.protocol}//${window.location.host}`}
            onSuccess={onSuccess}
            onError={(error) => {
              onError();
            }}
          />
          {errorMessage && (
            <div
              className="error"
              style={{
                margin: 'auto',
                color: 'red',
              }}
            >
              {errorMessage}
            </div>
          )}
        </>
      </ContentBox>
    </AbsoluteCenter>
  );
}

Login.propTypes = {
  loading: PropTypes.bool,
  onLoginSuccess: PropTypes.func,
};

export default Login;
